<template>
  <content-container
    title="Choose a file"
    description="Begin by selecting a file type and provider from the options below."
    class="operations-upload"
  >
    <fade-transition>
      <mds-loader v-if="!allDataHasLoaded" />
      <mds-form v-else>
        <mds-select
          v-model="selectedImportType"
          label="File Type:"
          :options="fileTypeList"
          :disabled="disableFileTypeSelect"
        />
        <mds-fieldset
          v-if="showQuarterEndQuestion"
          horizontal
          variation="radio-group"
          legend="This file have Quarterly Data?"
        >
          <mds-radio-button
            v-model="isQuarterlyData"
            name="isQuarterlyData"
            value="yes"
          >
            Yes
          </mds-radio-button>
          <mds-radio-button
            v-model="isQuarterlyData"
            name="isQuarterlyData"
            value="no"
          >
            No
          </mds-radio-button>
        </mds-fieldset>
        <mds-fieldset
          v-if="showQuarterEndInputs"
          horizontal
          class="operations-upload__quarter-end-select"
        >
          <mds-select
            v-model="selectedQEDate"
            hidden-label
            label="Select Quarter End Date"
            :options="quarterEndList"
          />
          <mds-select
            v-model="selectedYear"
            hidden-label
            label="Select Year"
            :options="yearList"
          />
        </mds-fieldset>
        <mds-select
          v-if="hideProviderSelect"
          v-model="selectedProvider"
          label="Provider:"
          :options="providerList"
          :disabled="disableProviderSelect"
        />
        <fade-transition>
          <template v-if="selectedImportTypeInputSections">
            <div>
              <file-upload
                v-for="(inputSection, inputSectionIndex) in selectedImportTypeInputSections"
                :key="inputSectionIndex"
                v-model="inputSection.files"
                v-bind="getFileInputConfig(inputSection)"
              />
            </div>
          </template>
        </fade-transition>
        <mds-button
          :disabled="disableSubmitButton || disableEdit"
          variation="primary"
          @click.prevent="uploadFiles"
        >
          Upload
        </mds-button>
      </mds-form>
    </fade-transition>
    <!-- TODO: Figure out positioning -->
    <mds-dialog
      v-model="dialogOpen"
      action-required
      width="500px"
    >
      <!-- Default slot -->
      <template>
        <div
          v-if="dialogState === DialogStates.IN_PROGRESS"
          class="operations-upload__dialog-content"
        >
          <heading
            :level="1"
            class="operations-upload__dialog-content-heading"
          >
            {{ uploadProgressDialogCopy }}
          </heading>
          <progress-bar
            v-model="uploadProgress"
            class="operations-upload__dialog-content-progress-bar"
          />
          <span class="operations-upload__dialog-content-progress">{{ uploadProgressPercentage }}</span>
        </div>
        <div
          v-else-if="dialogState === DialogStates.ERROR"
          class="operations-upload__dialog-content"
        >
          <img
            :src="`${publicPath}images/icons/alert.svg`"
            class="operations-upload__dialog-content-icon"
          >
          <heading
            :level="1"
            class="operations-upload__dialog-content-heading"
          >
            Upload Error
          </heading>
          <p class="operations-upload__dialog-content-description">
            {{ errorMessageDisplay }}
          </p>
        </div>
        <div
          v-else-if="dialogState === DialogStates.SUCCESSFUL"
          class="operations-upload__dialog-content"
        >
          <img
            :src="`${publicPath}images/icons/check.svg`"
            class="operations-upload__dialog-content-icon"
          >
          <heading
            :level="1"
            class="operations-upload__dialog-content-heading"
          >
            {{ uploadSuccessDialogCopy }}
          </heading>
          <p class="operations-upload__dialog-content-description">
            You will receive an alert when the file(s) have been processed.
          </p>
        </div>
      </template>
      <!-- Right actions slot -->
      <template v-slot:mds-dialog-actions-right>
        <template v-if="dialogState === DialogStates.IN_PROGRESS">
          <!-- Bit of a hack to not display the default content in the slot -->
          <span />
        </template>
        <mds-button-container
          v-else-if="dialogState === DialogStates.ERROR"
          right-aligned
        >
          <mds-button :href="$router.resolve({ name: 'operations-historical' }).href">
            View Historical
          </mds-button>
          <mds-button
            variation="primary"
            type="button"
            @click="continueUploading(true)"
          >
            Upload File(s)
          </mds-button>
        </mds-button-container>
        <mds-button-container
          v-else-if="dialogState === DialogStates.SUCCESSFUL"
          right-aligned
        >
          <mds-button
            type="button"
            @click="continueUploading(false)"
          >
            Continue Uploading
          </mds-button>
          <mds-button
            :href="$router.resolve({ name: 'operations-historical' }).href"
            variation="primary"
          >
            View Historical
          </mds-button>
        </mds-button-container>
      </template>
    </mds-dialog>
  </content-container>
</template>

<script>
import FadeTransition from '../../components/Transitions/FadeTransition'
import { MdsButton, MdsButtonContainer } from '@mds/button'
import MdsDialog from '@mds/dialog'
import MdsForm from '@mds/form'
import MdsLoader from '@mds/loader'
import MdsSelect from '@mds/select'
import MdsFieldset from '@mds/fieldset'
import MdsRadioButton from '@mds/radio-button'
import ContentContainer from '../../components/ContentContainer/ContentContainer'
import FileUpload from '../../components/FileUpload/FileUpload'
import Heading from '../../components/Heading/Heading'
import ProgressBar from '../../components/ProgressBar/ProgressBar'
import OperationsService from '../../services/operations-service'
import { GET_USER, GET_PROVIDERS, GET_IMPORT_UPLOAD } from '../../graqhql/queries'
import { CREATE_FILE_UPLOAD_TRACKING } from '../../graqhql/mutations'
import { NO_CACHE } from '../../graqhql/fetch-policies'
import {
  FUND_UNIVERSE_SINGLE,
  FUND_UNIVERSE_CSV,
  FUND_UNIVERSE_FIDELITY,
  PLAN_LIST,
  RJ_PLAN_CONTACT,
} from '../../../shared/constants/import-types'
import RoleAccess from '../../services/role-access-service'
import { RadarViews } from '../../../shared/constants/radar-views'

const IMPORT_TYPE_CONFIG = {
  [FUND_UNIVERSE_SINGLE]: {
    name: 'Fund Universe',
    fileTypes: {
      all: {
        accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/xml',
        path: ({ provider }) => `${provider}`,
      },
    },
  },
  [FUND_UNIVERSE_CSV]: {
    name: 'Fund Universe (CSV)',
    fileTypes: {
      funds: {
        label: 'Add Funds File',
        accept: '.csv',
        path: ({ provider }) => `${provider}/Funds`,
      },
      products: {
        label: 'Add Products File',
        accept: '.csv',
        path: ({ provider }) => `${provider}/Products`,
      },
    },
  },
  [FUND_UNIVERSE_FIDELITY]: {
    name: 'Fund Universe (Fidelity CSV)',
    fileTypes: {
      advisor: {
        label: 'Add Advisor File',
        accept: '.csv',
        path: ({ provider }) => `${provider}/Advisor`,
      },
      direct: {
        label: 'Add Direct File',
        accept: '.csv',
        path: ({ provider }) => `${provider}/Direct`,
      },
    },
  },
  [PLAN_LIST]: {
    name: 'Plans',
    fileTypes: {
      all: {
        accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/xml, text/plain',
        path: ({ provider }) => `${provider}`,
      },
    },
  },
  [RJ_PLAN_CONTACT]: {
    name: 'RJ Plan Contact',
    fileTypes: {
      all: {
        accept: '.csv',
        path: 'queued/Raymond James',
      },
    },
    hideProvider: true,
  },
}

const currentYear = new Date().getFullYear()
const QE_DATE_OPTIONS = ['3/31', '6/30', '9/30', '12/31']
const YEAR_OPTIONS = [currentYear, currentYear - 1, currentYear - 2]

const INITIAL_SELECTED_IMPORT_TYPE = ''
const INITIAL_SELECTED_PROVIDER = ''
const INITIAL_SELECTED_QE_DATE = ''
const INITIAL_SELECTED_YEAR = ''
const INITIAL_UPLOAD_PROGRESS = 0
const IS_QUARTERLY_DATA_NEGATIVE = 'no'
const IS_QUARTERLY_DATA_AFFIRMATIVE = 'yes'
const FILE_TRACKING_STATUS = 'RECEIVED'
const FILE_TRACKING_UPLOAD_METHOD = 'Radar'

const DialogStates = {
  IN_PROGRESS: 'in progress',
  SUCCESSFUL: 'successful',
  ERROR: 'error',
}

export default {
  components: {
    FadeTransition,
    MdsButton,
    MdsButtonContainer,
    MdsDialog,
    MdsForm,
    MdsLoader,
    MdsSelect,
    MdsFieldset,
    MdsRadioButton,
    ContentContainer,
    FileUpload,
    Heading,
    ProgressBar,
  },
  apollo: {
    user: GET_USER,
    providers: {
      query: GET_PROVIDERS,
      variables () {
        return {
          selectedView: this.selectedView,
        }
      },
      error (error) {
        // Error handling for unauthorized users
        this.authErrorMessage = error?.message || error?.graphQLErrors[0].message
        this.$notifications.error({ text: this.authErrorMessage, tinted: true })
      },
    },
  },
  data () {
    return {
      user: null,
      providers: [],
      authErrorMessage: null,
      publicPath: process.env.BASE_URL,
      DialogStates,
      dialogState: DialogStates.IN_PROGRESS,
      dialogOpen: false,
      uploadProgress: INITIAL_UPLOAD_PROGRESS,
      selectedImportType: INITIAL_SELECTED_IMPORT_TYPE,
      selectedProvider: INITIAL_SELECTED_PROVIDER,
      isQuarterlyData: IS_QUARTERLY_DATA_NEGATIVE,
      selectedQEDate: INITIAL_SELECTED_QE_DATE,
      selectedYear: INITIAL_SELECTED_YEAR,
      disableEdit: RoleAccess.disableEdit,
      selectedView: RoleAccess.selectedView,
      importTypes: Object.entries(IMPORT_TYPE_CONFIG).filter(([key, value]) => {
        return !(this.selectedView === RadarViews.TEST && key === RJ_PLAN_CONTACT)
      })
        .map(([key, value]) => {
          const { name, fileTypes, hideProvider } = value
          return {
            id: key,
            name,
            fileTypes: Object.values(fileTypes).map(type => ({ files: [], ...type })),
            hideProvider: !!hideProvider,
          }
        }),
    }
  },
  computed: {
    allDataHasLoaded () {
      return !this.$apollo.queries.providers.loading
    },
    providerList () {
      return [{ text: 'Select an Option' }, ...this.providers.map(({ code }) => ({ text: code, value: code }))]
    },
    fileTypeList () {
      return [{ text: 'Select an Option' }, ...this.importTypes.map(({ id, name }) => ({ text: name, value: id }))]
    },
    showQuarterEndQuestion () {
      return this.selectedImportType === PLAN_LIST
    },
    showQuarterEndInputs () {
      return this.showQuarterEndQuestion && this.isQuarterlyData === IS_QUARTERLY_DATA_AFFIRMATIVE
    },
    quarterEndList () {
      return [{ text: 'Select Quarter End Date' }, ...QE_DATE_OPTIONS.map((item) => ({ text: item, value: item }))]
    },
    yearList () {
      return [{ text: 'Select Year' }, ...YEAR_OPTIONS.map((item) => ({ text: item, value: item }))]
    },
    selectedImportTypeConfig () {
      return this.importTypes.find(type => type.id === this.selectedImportType)
    },
    selectedImportTypeInputSections () {
      if (!this.selectedImportTypeConfig) {
        return null
      }
      return this.selectedImportTypeConfig.fileTypes
    },
    disableFileTypeSelect () {
      return !!this.authErrorMessage
    },
    disableProviderSelect () {
      return !this.selectedImportTypeConfig || this.disableFileTypeSelect
    },
    hideProviderSelect () {
      return !this.selectedImportTypeConfig?.hideProvider
    },
    disableSubmitButton () {
      if (!this.selectedImportTypeInputSections) {
        return true
      }
      return !this.selectedImportTypeInputSections.every(section => section.files.length > 0)
    },
    numberOfFilesToUpload () {
      return this.selectedImportTypeInputSections
        ? this.selectedImportTypeInputSections.reduce((count, { files }) => count + files.length, 0)
        : 0
    },
    uploadProgressDialogCopy () {
      return this.numberOfFilesToUpload === 1
        ? `${this.numberOfFilesToUpload} File is Uploading...`
        : `${this.numberOfFilesToUpload} Files are Uploading...`
    },
    uploadSuccessDialogCopy () {
      return this.numberOfFilesToUpload === 1
        ? `${this.numberOfFilesToUpload} file has successfully uploaded.`
        : `${this.numberOfFilesToUpload} files have successfully uploaded.`
    },
    uploadProgressPercentage () {
      return `${this.uploadProgress}%`
    },
    selectedProviderItem () {
      return this.providers.find(item => item.code === this.selectedProvider)
    },
    errorMessageDisplay () {
      const errorMessage = this.disableFileTypeSelect ? this.authErrorMessage : 'Please try uploading again.'
      return errorMessage
    },
  },
  watch: {
    isQuarterlyData () {
      this.clearQuarterEndInputs()
    },
    showQuarterEndQuestion () {
      this.clearQuarterEndInputs({ clearIsQuarterlyData: true })
    },
  },
  methods: {
    getFileInputConfig ({ label, accept, multiple }) {
      return { label, accept, multiple }
    },
    async uploadFiles () {
      this.openDialog()

      try {
        const uploadMetaData = {
          user: this.user.taggingId,
          importType: this.selectedImportType,
          quarterEndDate: this.selectedQEDate,
          year: this.selectedYear,
        }

        // Construct look-up table of files across "sections" with their "path" ("/provider/Advisor", "/provider/Direct", etc.) and File object
        const fileLookup = this.selectedImportTypeInputSections.reduce((allFiles, section) => {
          const { files, path } = section
          const sectionFiles = files.reduce((sectionFiles, file) => {
            // Using "lastModified" as a unique ID for each file
            sectionFiles[file.lastModified] = {
              file,
              path: typeof path === 'string' ? path : path({ provider: this.selectedProvider }),
            }
            return sectionFiles
          }, {})
          return { ...allFiles, ...sectionFiles }
        }, {})

        const importFiles = Object.entries(fileLookup).map(([key, value]) => {
          return { id: key, name: value.file.name, type: value.file.type, path: value.path }
        })
        // Query for data with pre-signed URL for each file
        // Use 'no-cache' fetch policy to get new trace_id and pre-signed URL for each file upload and avoid cached result
        const { data: { importUpload } } = await this.$apollo.query({
          query: GET_IMPORT_UPLOAD,
          variables: {
            files: importFiles,
            meta: uploadMetaData,
            selectedView: this.selectedView,
          },
          fetchPolicy: NO_CACHE,
        })

        // Create array with URL/File object pairings
        const importFilesWithSignedUrls = importUpload.files.map(file => {
          return {
            url: file.signedUrl,
            file: fileLookup[file.id].file,
          }
        })

        const responseData = await OperationsService.uploadImportFiles(importFilesWithSignedUrls, this.setUploadProgress)

        // Call tracking service api for 'Fund Universe' and 'Plans' file upload to update entry in historical table immediately
        if (this.selectedImportType !== RJ_PLAN_CONTACT) {
          this.createUploadTracking(importUpload.id, importUpload.spanId, importFiles, responseData)
        }

        this.dialogState = this.DialogStates.SUCCESSFUL
      } catch (error) {
        this.authErrorMessage = error.message || error?.graphQLErrors[0]?.message
        this.dialogState = this.DialogStates.ERROR
      }
    },
    createUploadTracking (traceId, spanId, importFiles, responseData) {
      const s3FileLocations = this.getS3Location(responseData, importFiles)
      this.$apollo.mutate({
        mutation: CREATE_FILE_UPLOAD_TRACKING,
        variables: {
          options: {
            clientId: this.selectedProviderItem.id,
            clientCode: this.selectedProvider,
            createdDate: new Date().toISOString(),
            createdBy: this.user.taggingId,
            fileType: this.selectedImportType,
            status: FILE_TRACKING_STATUS,
            uploadMethod: FILE_TRACKING_UPLOAD_METHOD,
            traceId: traceId,
            spanId: spanId,
            s3FileLocations: s3FileLocations,
          },
          selectedView: this.selectedView,
        },
      })
    },
    getS3Location (response, importFiles) {
      const s3fileLocations = importFiles.map((file, index) => {
        return {
          key: file.path + '/' + file.name,
          version: response[index].headers['x-amz-version-id'],
        }
      })
      return s3fileLocations
    },
    setUploadProgress (progress) {
      this.uploadProgress = progress
    },
    continueUploading (fromError = false) {
      this.clearSelections(!fromError)
      this.dialogOpen = false
    },
    openDialog () {
      this.dialogState = this.DialogStates.IN_PROGRESS
      this.dialogOpen = true
    },
    clearQuarterEndInputs ({ clearIsQuarterlyData } = {}) {
      if (clearIsQuarterlyData) {
        this.isQuarterlyData = IS_QUARTERLY_DATA_NEGATIVE
      }
      this.selectedQEDate = INITIAL_SELECTED_QE_DATE
      this.selectedYear = INITIAL_SELECTED_YEAR
    },
    clearSelections (excludeImportType = false) {
      const selectedImportTypeObject = this.selectedImportTypeConfig
      this.uploadProgress = INITIAL_UPLOAD_PROGRESS
      this.selectedImportType = INITIAL_SELECTED_IMPORT_TYPE
      this.selectedProvider = INITIAL_SELECTED_PROVIDER
      this.importTypes.forEach(importType => importType.fileTypes.forEach(fileType => {
        fileType.files = []
      }))
      this.clearQuarterEndInputs()
      if (!excludeImportType) {
        setTimeout(() => {
          this.selectedImportType = selectedImportTypeObject.name
        }, 300)
      }
    },
  },
}
</script>

<style lang="scss">
.operations-upload {
  max-width: 500px;
}

.operations-upload__dialog-content {
  padding-top: $mds-space-2-x;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.operations-upload__dialog-content-icon {
  margin: 0 0 $mds-space-3-x;
  max-width: 60px;
}

.operations-upload__dialog-content-heading {
  @include mds-level-4-heading();
  margin: 0 0 $mds-space-2-x;
}

.operations-upload__dialog-content-description {
  margin: 0 0 $mds-space-2-x;
}

.operations-upload__dialog-content-progress-bar {
  margin: 0 0 $mds-space-1-x;
}

.operations-upload__dialog-content-progress {
  @include mds-body-text-s();
}
.operations-upload__quarter-end-select {
  .mds-label___radar {
    flex: 1;
  }
}
</style>
