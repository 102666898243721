<template>
  <div
    :class="modifiers"
    class="file-upload"
  >
    <label class="file-upload__label mds-button mds-button--secondary">
      {{ label }}
      <input
        class="file-upload__input"
        type="file"
        name="test"
        :accept="accept"
        :multiple="multiple"
        @change="onChange"
      >
    </label>
    <mds-tag-container
      v-if="!noFilesSelected"
      class="file-upload__file-group"
    >
      <mds-tag
        v-for="(file, index) in internalFiles"
        :key="index"
        :text="file.name"
        :removable="true"
        remove-icon-aria-label="Remove file"
        @mds-tag-removed="removeFile(index)"
      />
    </mds-tag-container>
  </div>
</template>

<script>
import { MdsTagContainer, MdsTag } from '@mds/tag'

export default {
  components: {
    MdsTagContainer,
    MdsTag,
  },
  model: {
    prop: 'files',
    event: 'change',
  },
  props: {
    accept: {
      type: String,
      default: null,
    },
    files: {
      type: Array,
      default: () => [],
    },
    label: { // REVIST NAME
      type: String,
      default: 'Add File(s)',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      internalFiles: [],
    }
  },
  computed: {
    noFilesSelected () {
      return this.internalFiles.length === 0
    },
    modifiers () {
      return [this._emptyModifier]
    },
    _emptyModifier () {
      return this.noFilesSelected ? 'file-upload--empty' : null
    },
  },
  watch: {
    files: {
      immediate: true,
      handler () {
        this.internalFiles = this.files
      },
    },
  },
  methods: {
    onChange (e) {
      const files = e.target.files
      if (files.length === 0) return
      this.addFiles(files)
    },
    addFiles (files) {
      this.internalFiles = Array.from(files)
      this.$emit('change', this.internalFiles)
    },
    removeFile (index) {
      this.internalFiles.splice(index, 1)
      this.$emit('change', this.internalFiles)
    },
  },
}
</script>

<style lang="scss">
@import '@mds/button/src/button.scss';

.file-upload {
  margin: 0 0 $mds-space-2-x 0;

  &--empty {
    .file-upload__label {
      margin: 0;
    }
  }
}

.file-upload__label {
  margin: 0 0 $mds-space-2-x 0;
}

.file-upload__input {
  @include mds-accessibly-hidden;
}

.file-upload__file-group {
  display: block;
}
</style>
