<template>
  <progress
    :value="value"
    :max="max"
    class="progress-bar"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
  },
}
</script>

<style lang="scss" scoped>
.progress-bar { /* All HTML5 progress enabled browsers */
  /* Turns off styling */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /* gets rid of default border in Firefox and Opera. */
  border: none;
  /* Dimensions */
  width: 100%;
  height: 14px;
  background-color: $mds-color-neutral-90;
}

/* Chrome */
.progress-bar::-webkit-progress-bar {
  background-color: $mds-color-neutral-90;
  border-radius: $mds-border-radius-panel;
}

/* IE10 */
.progress-bar {
  color: $mds-chart-color-13;
  border-radius: $mds-border-radius-panel;
}

/* Firefox */
.progress-bar::-moz-progress-bar {
  background: $mds-chart-color-13;
  border-radius: $mds-border-radius-panel;
}

/* Chrome */
.progress-bar::-webkit-progress-value {
  background: $mds-chart-color-13;
  border-radius: $mds-border-radius-panel;
  transition: all $mds-animation-timing-2-and-a-half-x $mds-animation-ease-out;
}
</style>
